<!--
File: TranslateEditForm.vue
Description: form for adding/editing a single translation.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.items.length"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout">
        <BaseDropdown class="md-layout-item md-small-size-100 md-size-50" :class="getClass('category')"
          :label="$t('translate.category')" v-model="formData.category" :items="getTranslationCategories"
          data-vv-name="category" v-validate="validations.category" :isRequired="true" required />

        <md-field class="md-layout-item md-small-size-100 md-size-50" :class="getClass('key')">
          <label for="key">{{ $t('translate.key') }}</label>
          <md-input id="key" v-model="formData.key" type="text" data-vv-name="key" v-validate="validations.key"
            required />
        </md-field>
      </div>

      <MultilanguageEditForm ref="dynamicForm" v-model="formData" :fields="nameFields" :validations="validations" />
    </template>
  </modal>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { Modal, BaseDropdown, MultilanguageEditForm } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import { savedMessage } from '@/mixins/messagesMixin'

  export default {
    name: 'translations-edit-form',
    mixins: [permissions],

    data() {
      return {
        formName: 'TranslateEditForm',
        eligible: false,

        formData: {
          category: null,
          key: null,
          en: null,
          ru: null,
          kg: null,
        },

        nameFields: [
          { name: 'en', label: 'stdCols.name_en' },
          { name: 'ru', label: 'stdCols.name_ru' },
          { name: 'kg', label: 'stdCols.name_kg' },
        ],

        initialFormState: null,
        validations: {
          category: { required: true, min: 3 },
          key: { required: true, min: 3 },
          en: { required: true, min: 1 },
          ru: { required: true, min: 1 },
          kg: { required: true, min: 1 },
        }
      }
    },

    props: {
      translationItem: null
    },

    components: {
      Modal,
      BaseDropdown,
      MultilanguageEditForm
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed();
      if (!this.eligible) {
        this.$emit('close');
        return;
      };

      if (this.translationItem) {// Load data
        this.formData = this.translationItem;
      };

      this.$nextTick(async () => {
        this.initialFormState = this.getCurrentState;
        await this.$validator.validateAll();
        await this.$refs.dynamicForm.validateForm();
      })
    },

    methods: {
      ...mapActions({
        addTranslation: 'ADD_TRANSLATE_ENTRY',
        editTranslation: 'UPD_TRANSLATE_ENTRY',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      async validate() {
        const isValid = await this.$validator.validateAll();
        const isMultilanguageValid = await this.$refs.dynamicForm.validateForm();
        if (!isValid || !isMultilanguageValid) return

        const translation = this.getCurrentState;

        let errDesc = '';
        let newId;
        const action = !this.translationItem ? this.addTranslation : this.editTranslation;
        const payload = !this.translationItem ? translation : { id: this.translationItem.id, item: translation };
        try {
          const res = await action(payload)
          newId = res?.id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        this.$emit('close')
        await savedMessage(errDesc, this.$t('route.translate'), this.formData.ru, this.formData.en)
        this.highlightRow(newId)
      },
    },

    computed: {
      ...mapGetters(['getTranslationCategories']),

      screenTitle() {
        return this.translationItem ? this.$t('screen_titles.translate_upd') : this.$t('screen_titles.translate_add')
      },

      getCurrentState() {
        return {
          category: this.formData.category,
          key: this.formData.key,
          en: this.formData.en,
          ru: this.formData.ru,
          kg: this.formData.kg
        }
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false

        return this.formData.category !== this.initialFormState.category ||
          this.formData.key !== this.initialFormState.key ||
          this.formData.en !== this.initialFormState.en ||
          this.formData.ru !== this.initialFormState.ru ||
          this.formData.kg !== this.initialFormState.kg
      }
    }
  }
</script>