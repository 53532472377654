<!--
File: Translate.vue
Description: add/edit translation of terms in the DB.
-->
<template>
  <md-card v-if="eligible">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <BaseDropdown :label="$t('label.categories')" v-model="selectedCategory" :items="getTranslationCategories"
            @input="reloadData" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery" />
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem(null)">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        md-fixed-header :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.search_again')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.id === highlightedRow }"
          :id="item.id === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell :md-label="$t('label.category')" md-sort-by="category">
            {{ item.category }}
          </md-table-cell>
          <md-table-cell :md-label="$t('translate.key')" md-sort-by="key">
            <span>{{ item.key }}</span>
          </md-table-cell>
          <md-table-cell v-for="lang_item in langs" :key="lang_item" :md-label="$t(`translate.${lang_item}`)"
            :md-sort-by="`${lang_item}`">
            {{ item[lang_item] }}
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <md-button v-if="isBtnAllowed('EditButton')" class="md-just-icon md-default md-simple"
              :title="$t('buttons.edit')" @click.native="viewItem(item.id)">
              <md-icon>edit</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
    <EditForm v-if="showDetailsDlg" :translationItem="selectedItem" @close="showDetailsDlg = false" />
  </md-card>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { Pagination } from '@/components'
  import { BaseDropdown } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import Fuse from 'fuse.js'
  import EditForm from './TranslateEditForm.vue'

  export default {
    name: 'translations-list',
    mixins: [permissions, listView],

    data() {
      return {
        formName: 'Translate',
        eligible: false,
        showSpinner: false,
        showDetailsDlg: false,

        selectedCategory: null,
        selectedItem: null,

        langs: ['en', 'ru', 'kg'],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['category', 'key', 'en', 'ru', 'kg'],
        currentSort: 'key',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },
    components: {
      Pagination,
      BaseDropdown,
      EditForm
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.onClose()
        return
      };

      this.toggleSpinner(true)
      await this.loadTranslations()
      await this.reloadData()
    },

    methods: {
      ...mapActions({
        loadTranslations: 'LOAD_TRANSLATION_LIST',
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      async reloadData() {
        this.toggleSpinner(true)
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })
        this.toggleSpinner(false)
      },

      viewItem(id) {
        this.selectedItem = this.tableData.find(item => (item.id == id))
        this.showDetailsDlg = true
      },
    },

    computed: {
      ...mapState({
        highlightedRow: (state) => state.History.row
      }),
      ...mapGetters(['getTranslationCategories', 'getFilteredTranslations']),

      tableData() {
        const res = this.getFilteredTranslations(this.selectedCategory)
        return this.customSort(res, 'description')
      }
    },

    watch: {
      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>